import React, {useRef} from 'react'
import {useIsVisible} from '@hook/useIsVisible'
import FadeInView from '@component/animation/fadeIn/FadeInView'
import Text from '@component/Text/Text'
import {useTranslation} from 'next-i18next'
import classNames from 'classnames'
import Link from 'next/link'
import XImageSource from '@svg/sns/ic_x.svg'
import TelegramImageSource from '@svg/sns/ic_telegram.svg'
import DiscordImageSource from '@svg/sns/ic_discord.svg'
import {createLog} from '@util/logs'
import WizImage from '@component/images/WizImage'
import ic_apple_ko from '@image/token/ic_apple_ko.webp'
import ic_apple_en from '@image/token/ic_apple_en.webp'
import ic_android_ko from '@image/token/ic_android_ko.webp'
import ic_android_en from '@image/token/ic_android_en.webp'
import {useRouter} from 'next/router'

interface IProps {
    className?: string
}

const ICON_SIZE = 30

const TokenSaleTitleContainer: React.FC<IProps> = ({className}) => {
    const {t} = useTranslation()
    const fadeAnimationRef = useRef(null)
    const isVisible = useIsVisible(fadeAnimationRef)
    const router = useRouter()

    const onClickSns = (sns: 'twitter' | 'telegram' | 'facebook' | 'instagram' | 'discord') => {
        createLog('event', 'token_landing_header_click_sns', {sns})
    }
    return (
        <div ref={fadeAnimationRef} className={`w-full ${className}`}>
            <div className={'space-y-[10px]'}>
                <FadeInView isVisible={isVisible} delay={'delay-100'}>
                    <Text
                        className={
                            'text-landing_display1 text-white md:text-landing_body_large md:font-semibold sm:text-landing_body_small sm:font-semibold !font-landing'
                        }>
                        {t('landingTokenSale.desc1')}
                    </Text>
                    <Text
                        className={classNames(
                            'typo-landing_heading1 text-[70px] leading-[90px] md:typo-landing_heading2 sm:typo-landing_display1',
                            'bg-gradient-token inline-block text-transparent bg-clip-text',
                        )}>
                        {t('landingTokenSale.desc2')}
                    </Text>
                </FadeInView>
                <FadeInView isVisible={isVisible} delay={'delay-300'}>
                    <Text className={'typo-landing_body_medium text-[#C9CAC3] md:typo-landing_body_caption'}>
                        {t('landingTokenSale.desc3')}
                    </Text>
                </FadeInView>
            </div>
            <FadeInView isVisible={isVisible} delay={'delay-500'}>
                <div className={'py-[50px] land:py-[30px] md:py-[20px] space-y-[10px]'}>
                    <Text
                        className={classNames(
                            'text-landing_display1 md:text-landing_body_large md:font-semibold sm:text-landing_body_small sm:font-semibold !font-landing',
                            'bg-gradient-token inline-block text-transparent bg-clip-text',
                        )}>
                        {t('landingTokenSale.desc4')}
                    </Text>
                    <Text
                        className={
                            'typo-landing_body_medium text-[#C9CAC3] mt-[10px] md:typo-landing_body_caption pr-[60px] sm:pr-0'
                        }>
                        {t('landingTokenSale.desc5')}
                    </Text>
                </div>
            </FadeInView>
            <div className={'flex gap-x-[10px] items-center'}>
                <Link href={t('sns.twitter.url')} onClick={() => onClickSns('twitter')} target={'_blank'}>
                    <XImageSource width={ICON_SIZE} height={ICON_SIZE} />
                </Link>
                <Link href={t('sns.telegram.url')} onClick={() => onClickSns('telegram')} target={'_blank'}>
                    <TelegramImageSource width={ICON_SIZE} height={ICON_SIZE} />
                </Link>
                <Link href={t('sns.discord.url')} onClick={() => onClickSns('discord')} target={'_blank'}>
                    <DiscordImageSource width={ICON_SIZE} height={ICON_SIZE} />
                </Link>
            </div>
            <div className={'flex items-center gap-x-[10px] pt-[30px]'}>
                <div className={'cursor-pointer'} onClick={() => window.open(t('download.link.apple'))}>
                    <WizImage src={router?.locale === 'ko' ? ic_apple_ko : ic_apple_en} width={155} />
                </div>
                <div className={'cursor-pointer'} onClick={() => window.open(t('download.link.android'))}>
                    <WizImage src={router?.locale === 'ko' ? ic_android_ko : ic_android_en} width={155} />
                </div>
            </div>
        </div>
    )
}
export default TokenSaleTitleContainer
